import React, { useState, Suspense, useEffect } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import {
  VisibilityOutlinedIcon,
  VisibilityOffOutlinedIcon,
} from "./../common/assets/Images";
import { Link, withRouter } from "react-router-dom";
import { Schema } from "./Schema";
import {
  GenericAPIService,
  EncryptStorage,
  UrlConstants,
  Constants,
  HOF_Methods,
} from "../common/imports/CommonImport";
import PropagateLoader from "react-spinners/PropagateLoader";
import {
  Google_CLIENT_ID,
  Google_Provider,
  Facebook_CLIENT_ID,
  Facebook_Provider,
  Amazon_CLIENT_ID,
  Amazon_Provider,
} from "./social-media-login/AppClient_ID";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import environment from "../../environments/environment";
import jwtDecode from "jwt-decode";
import { chatCommonService } from "../../services/chatCommonService";
import EmailVerification from "../register/emailVerification/EmailVerification";
import { loginHelper } from "./loginHelper";
import { checkIsTrue } from "../course/ChapterLaunchHelper";
import "./LoginForm.scss";
import { button_right_arrow } from "../common/assets/Images";
// import { CheckCircleIcon, LockIcon, long_right_arrow } from '../common/assets/Images';

const SocialLoginButton = React.lazy(() =>
  import("./social-media-login/SocialLoginButton")
);
const PaypalLoginButton = React.lazy(() =>
  import("../paypal-login/PaypalLoginButton")
);
const getLoader = (
  <div className="loader">
    <PropagateLoader size={15} />
  </div>
);

const LoginForm = (props) => {
  const { history } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [customAttributesList, setCustomAttributesList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [accessDeniedModalShow, setAccessDeniedModalShow] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [loginStudentId, setLoginStudentId] = useState(null);
  const [loginResponse, setLoginResponse] = useState([]);
  const [firstSubmitClicked, setFirstSubmitClicked] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [bdiLogin, setBdiLogin] = useState(false);
  const [bdiToken, setBdiToken] = useState(null);
  const [improvRegisterModal, setImprovRegisterModal] = useState(false);
  const [tempOrganisationId, setTempOrganisationId] = useState();

  loginHelper.resetStorageValues();

  // this hook form feting custom Attribute current updated array and updating to the state veriable
  useEffect(() => {
    const currentUrl = window.location.href;

    const params = new URLSearchParams(currentUrl.split("?")[1]);

    const authTokenParam = params.get("authToken");

    if (!authTokenParam) {
      localStorage.clear();
      window.localStorage.clear();
    }

    chatCommonService.onMessage().subscribe((res) => {
      if (res) {
        const { organisationCustomAttributeMappingList } = res;
        if (organisationCustomAttributeMappingList) {
          setCustomAttributesList(organisationCustomAttributeMappingList);
        }
        if (
          organisationCustomAttributeMappingList &&
          organisationCustomAttributeMappingList.length > 0
        ) {
          organisationCustomAttributeMappingList.forEach((item) => {
            if (item.customAttribute.columnName === "PAYMENT_BYPASS") {
              item.customAttributeValue === "true"
                ? EncryptStorage.setToLocalStorage("PAYMENT_BYPASS", true)
                : EncryptStorage.setToLocalStorage("PAYMENT_BYPASS", false);
            } else if (
              item.customAttribute.columnName === "EMAIL_VERIFICATION"
            ) {
              item.customAttributeValue === "true"
                ? EncryptStorage.setToLocalStorage("EMAIL_VERIFICATION", true)
                : EncryptStorage.setToLocalStorage("EMAIL_VERIFICATION", false);
            }
          });
        }
      }
    });
    if (props.organisationId) {
      setTempOrganisationId(props.organisationId);
      EncryptStorage.setToLocalStorage("organisationId", props.organisationId);
    }
  }, [props]);

  // useEffect(()=>{
  //    alert('props.organisationId'+props.organisationId);
  //    setTempOrganisationId(props.organisationId)
  // },[props.organisationId]);

  useEffect(() => {
    EncryptStorage.setToLocalStorage("parentEmailFlow", false);
    const getUrl = window.location.href;
    let splitUrl = window.location.href.includes("?") ? true : false;
    if (splitUrl) {
      let replaceUrl = getUrl.replace("#/", "");
      let url = new URL(replaceUrl);

      let isAuthToken = window.location.href.includes("?authToken=")
        ? true
        : false;

      if (isAuthToken) {
        let authToken = url.searchParams.get("authToken");
        let isPDS = url.searchParams.get("studentSource");
        localStorage.setItem("IsLoggedIn", "true");
        EncryptStorage.setToLocalStorage("redirectDashboard", true);
        EncryptStorage.setToLocalStorage("backendLogin", true);
        EncryptStorage.setToLocalStorage("isPDS", isPDS ? true : false);

        EncryptStorage.encryptOnlyKey("token", authToken);

        history.push("/home/personal-information");
      } else {
        let fullToken = url.searchParams.get("token");
        let isPDS = url.searchParams.get("studentSource");
        if (fullToken || isPDS) {
          let errorCode = "";
          let isErrorCode = window.location.href.includes("errorcode=")
            ? true
            : false;
          localStorage.setItem("IsLoggedIn", "true");
          if (isErrorCode) {
            errorCode = url.searchParams.get("errorcode");
          }
          let decodedValue = jwtDecode(fullToken);
          let tempValue = JSON.parse(decodedValue.sub);
          let tempSecondaryCourseIds =
            tempValue.secondaryConfigCourseIds.split(",");

          EncryptStorage.setToLocalStorage(
            "primaryConfigCourseId",
            tempValue.primaryConfigCourseId
          );
          EncryptStorage.setToLocalStorage(
            "secondaryConfigCourseIds",
            tempSecondaryCourseIds
          );
          EncryptStorage.setToLocalStorage("parentEmailFlow", true);
          EncryptStorage.encryptOnlyKey("token", fullToken);
          EncryptStorage.setToLocalStorage(
            "configCourseId",
            tempValue.primaryConfigCourseId
          );

          EncryptStorage.setToLocalStorage(
            "organisationId",
            tempValue.organisationId
          );
          EncryptStorage.setToLocalStorage("studentId", tempValue.studentId);
          EncryptStorage.setToLocalStorage(
            "studentPaymentTrxId",
            tempValue.studentPaymentTrxId
          );
          history.push("/home/parent-cart-summary");
        }
      }
    }
  }, []);

  const onSubmit = (values) => {
    setFirstSubmitClicked(true);
    if (!firstSubmitClicked) {
      userLogin(values);
    } else {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        userLogin(values);
      }, 3000);
    }
  };

  const loginSubmit = (url, payload, res) => {
    setLoader(true);
    GenericAPIService.customAPIMethod(url, payload, "POST")
      .then((resp) => {
        setLoader(false);
        if (HOF_Methods.getStatus(resp) === "success") {
          if (resp.token && resp.token !== "") {
            setImprovRegisterModal(true);
            setBdiToken(resp.token);
          }
        } else if (resp.status.message != "Invalid Email") {
          setImprovRegisterModal(true);
          setBdiToken(null);
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err && err?.status?.message && err?.status?.message) {
          setPasswordError(err?.status?.message);
        }
      });
  };
  const redirectToBdi = () => {
    setImprovRegisterModal(false);
    setLoader(true);
    if (bdiToken) {
      window.location.href =
        "https://course.myimprov.com/Login.ashx?token=" + bdiToken;
    } else {
      setBdiLogin(true);
    }
  };
  const userLogin = (values) => {
    EncryptStorage.setToLocalStorage("parentEmailFlow", false);
    EncryptStorage.setToLocalStorage("isContractDownloaded", false);
    let url = UrlConstants.loginUrl; 
    values.domainUrl ="string.com";

    const payload = values;
    setErrorMessage("");
    setEmailError("");
    setPasswordError("");
    setLoader(true);
    let initLoginUrl = UrlConstants.initloginUrl;
    GenericAPIService.loginMethod(url, payload, "POST")
      .then((res) => {
        setLoader(false);
        if (HOF_Methods.getStatus(res) === "success") {
          setLoginResponse(res);
          if (res) {
            const currentDate = new Date();
            EncryptStorage.setToLocalStorage("expiration_time", currentDate);
            const token = res.token;
            loginHelper.assignDefaultStorageValues(res);
            const student = res.data.student ? res.data.student : res.data;
            const studentId = student.studentId;
            EncryptStorage.setToLocalStorage("sessionTime", "true");
            if (student.status.replaceAll(" ", "") === "PaymentPending") {
              // if (student.isVerified) {
              //    history.push("/home/settings");
              // }
              if (
                student.isVerified != undefined &&
                student.isVerified.toString() == "false"
              ) {
                const token = res.token;
                EncryptStorage.encryptOnlyKey("tokenCopy", token);
                EncryptStorage.setToLocalStorage(
                  "redirectUrlName",
                  "/home/settings"
                );
                setLoginStudentId(studentId);
                setEmailId(student.emailId);
                EncryptStorage.encryptOnlyKey("tokenCopy", token);
                setEmailVerification(true);
              } else {
                history.push("/home/settings");
              }
            } else if (student.defaultStPaymentTrxId == undefined) {
              EncryptStorage.encryptOnlyKey("token", token);
              history.push("/home/dashboard");
            } else {
              EncryptStorage.encryptOnlyKey("token", token);
              history.push("/home/dashboard");
            }
          }
        } else {
          if (
            res &&
            res.data &&
            res.data.isPasswordExpired !== undefined &&
            res.data.isPasswordExpired
          ) {
            setTimeout(() => {
              history.push("/forgot-password");
            }, 2000);
          } else if (
            res &&
            res.status &&
            res.status.message == "Please enter a valid email address"
          ) {
            setEmailError(res.status.message);
            loginSubmit(initLoginUrl, payload, res);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setPasswordError(
            "Incorrect email or password. Please try again or click Forgot Password to reset your password."
          );
          setLoader(false);
          return;
        }
        setLoader(false);
        loginSubmit(initLoginUrl, payload);
        setErrorMessage(Constants.serverErrorMessage);
      });
  };
  const checkingAllAttributes = (name) => {
    let result = null;
    if (customAttributesList && customAttributesList.length > 0) {
      customAttributesList.forEach((item) => {
        if (item.customAttribute.columnName === name) {
          if (name === "SOCIAL_LOGIN") {
            result = false;
          } else {
            result = item.customAttributeValue;
          }
        }
      });
      return result;
    } else {
      return result;
    }
  };
  const closeBdiModal = () => {
    setBdiLogin(false);
    setLoader(true);
    window.location.href = "https://course.myimprov.com/logon.aspx";
  };

  const redirectToMyImprov = () => {
    setImprovRegisterModal(false);
    setLoader(true);
    window.location.href = "https://www.myimprov.com/";
  };
  const redirectNotEegistered = () => {
    let id = EncryptStorage.getLocalStorage("organisationId");
    if (id == "1481") {
      // window.location.href = "https://www.improvlearning.com/gsa";
      window.open("https://www.improvlearning.com/gsa", "_blank");
    } else {
      // window.location.href = "https://www.myimprov.com";
      window.open("https://www.myimprov.com", "_blank");
    }
  };

  return (
    <div className="register mt-4">
      {loader && getLoader}
      <h1 className="login-welcome-1 text-center">Welcome back!</h1>
      <h5 className="login-welcome-2 text-center">
        Let's get you back to your course.
      </h5>
      {/* <h4 className="mb-2 mb-lg-3"> Login to your account</h4> */}
      {/*
                Checking for existing SocialLoginButton there or not
                if it exists then getting true wil to the true boolean vale sould be show social login buttons 
            */}
      {checkingAllAttributes("SOCIAL_LOGIN") === "true" ? (
        <>
          <div
            className="register__buttons mb-3"
            style={{ textAlign: "center" }}
          >
            <div className="desktop-icon">
              <button className="btn">
                <Suspense fallback={getLoader}>
                  <SocialLoginButton
                    setLoader={setLoader}
                    setErrorMessage={setErrorMessage}
                    isRegister={false}
                    appId={Google_CLIENT_ID}
                    provider={Google_Provider}
                    {...props}
                  />
                </Suspense>
              </button>
              <button className="btn">
                <Suspense fallback={getLoader}>
                  <SocialLoginButton
                    setLoader={setLoader}
                    setErrorMessage={setErrorMessage}
                    isRegister={false}
                    appId={Facebook_CLIENT_ID}
                    provider={Facebook_Provider}
                    {...props}
                  />
                </Suspense>
              </button>
              {/*
                        <button className="btn">
                            <Suspense fallback={getLoader}>
                                <SocialLoginButton setLoader={setLoader} setErrorMessage={setErrorMessage} isRegister={false}
                                    appId={Amazon_CLIENT_ID} provider={Amazon_Provider} {...props} />
                            </Suspense>
                        </button> */}
            </div>
          </div>
        </>
      ) : null}
      {checkingAllAttributes("SOCIAL_LOGIN") === "true" ? (
        <h6 className="login-title1">Or login using your email</h6>
      ) : null}
      <div className="register__form mt-0 mt-lg-2 px-2 px-lg-0">
        <Formik
          initialValues={Schema.initialValues}
          validationSchema={Schema.validationSChema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, setFieldValue, values, errors, getFieldProps }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                requireSSL={true}
              >
                {errorMessage && (
                  <span className="custom-error">{errorMessage}</span>
                )}
                <Row className="align-items-center RegisterForm mt-2.3 mt-lg-2.3">
                  <Col md={12} xl={12}>
                    <div style={{ width: "90%", margin: "auto" }}>
                      <div className="email_font">Email Address</div>
                      <div className="custom-forms-login">
                        <Form.Control
                          name="emailId"
                          placeholder="your@email.com"
                          type="text"
                          onChange={(e) => {
                            setFieldValue("emailId", e.target.value);
                            setEmailError("");
                          }}
                        />
                        {errors.emailId ? (
                          <ErrorMessage
                            component="div"
                            className="error"
                            name="emailId"
                          />
                        ) : (
                          <div className="error">{emailError}</div>
                        )}
                        {/* {values.emailId ? (
                        <i
                          className="TextInputField__icon clear"
                          onClick={() => setFieldValue("emailId", "")}
                        >
                          <HighlightOffIcon />
                        </i>
                      ) : null} */}
                      </div>
                    </div>
                  </Col>
                  <Col md={12} xl={12}>
                    <div
                      className="custom-forms-login clear-password"
                      style={{ width: "90%", margin: "auto" }}
                    >
                      <div
                        className="password_font"
                        style={{
                          marginTop: "20px",

                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                      >
                        Password
                      </div>
                      <div>
                        <Form.Control
                          name="password"
                          className="password-field"
                          maxLength={20}
                          autoComplete="new-password"
                          placeholder="Password"
                          password
                          type={(visibility && "text") || "password"}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value);
                            setPasswordError("");
                          }}
                        />

                        {errors.password ? (
                          <ErrorMessage
                            component="div"
                            className="error"
                            name="password"
                          />
                        ) : (
                          <div className="error">{passwordError}</div>
                        )}
                        <i className="TextInputField__icon password-eye1">
                          {(visibility && (
                            <VisibilityOutlinedIcon
                              className="iconColor"
                              onClick={() => setVisibility(false)}
                            />
                          )) || (
                            <VisibilityOffOutlinedIcon
                              className="iconColor"
                              onClick={() => setVisibility(true)}
                            />
                          )}
                        </i>
                        {/* {values.password ? (
                          <i
                            className="TextInputField__icon_password clear"
                            onClick={() => setFieldValue("password", "")}
                          >
                            <HighlightOffIcon />
                          </i>
                        ) : null} */}
                      </div>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <p className="small-text1 font-weight-bold mb-lg-2 mb-md-0 ml-lg-1">
                      <Link to="/forgot-password" className="link">
                        <span className="font-weight-bold ml-2 forget-password-text_final">
                          Forgot your password ?
                        </span>
                      </Link>
                    </p>
                  </Col>
                  <Col sm={12}>
                    <div
                      className="register__button mt-2 ml-lg-1"
                      style={{ justifyContent: "center" }}
                    >
                      <button
                        type="submit"
                        className="v2-btn1 standard mobile-full-width"
                      >
                        Login
                        <img
                          className="arrow_img"
                          src={button_right_arrow}
                        ></img>
                      </button>
                    </div>
                  </Col>
                  <Col sm={12} className="mt-4">
                    <p className="small-text2 font-weight-bold mb-lg-2 mb-0 ml-lg-1 d-lg-block d-flex justify-content-center">
                      {/* <Link onClick={() => { redirectNotEegistered() }} className="register-text forget-password-text">
                                    {" "}
                                    <span className="font-weight-normal ml-2">Not registered yet?</span>
                                 </Link> */}
                      {/* 
                      {tempOrganisationId == "1481" ? (
                        <a
                          href="https://www.improvlearning.com/gsa"
                          className="register-text forget-password-text"
                          target="_blank"
                        >
                        
                          <span className="notRegisterFont font-weight-normal ml-2">
                            Not registered yet?
                          </span>

                        </a>
                      ) : (
                        <a
                          href="https://www.myimprov.com"
                          className="register-text forget-password-text"
                          target="_blank"
                        >
                          
                          <span className="notRegisterFont font-weight-normal ml-2">
                            Not registered yet?
                          </span>
                        </a>
                      )} */}

                      <Link
                        to="/forgot-password"
                        className="register-text forget-password-text"
                      >
                        {" "}
                        <span className="troubleLoginFont font-weight-normal ml-2">
                          Trouble logging in?
                        </span>
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Modal
        show={accessDeniedModalShow}
        centered
        backdrop="static"
        keyboard={false}
        className="account-lock-modal"
      >
        <Modal.Body>
          <div className="account-lock-cont">
            <p className="mb-4">
              Your Token has been expired so you are redirected to Login Page.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      {improvRegisterModal && (
        <Modal
          show={improvRegisterModal}
          centered
          backdrop="static"
          keyboard={false}
          className=" white-rounded-modal login-modal"
        >
          <Modal.Body>
            <div className="login-modal-body">
              <p>
                <strong>We detected that you have a legacy account.</strong> If
                you wish to proceed, click here. Otherwise click below to
                register for a new account.
              </p>
              <div className="confirm-email-btn-block">
                <button
                  type="submit"
                  className="v2-btn standard mobile-full-width"
                  onClick={() => redirectToBdi()}
                >
                  Do you wish to proceed to your old account?
                </button>
                <button
                  type="submit"
                  className="v2-btn standard mobile-full-width"
                  onClick={() => redirectToMyImprov()}
                >
                  Register for a new account
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {bdiLogin && (
        <Modal
          show={bdiLogin}
          centered
          backdrop="static"
          keyboard={false}
          className=" white-rounded-modal login-modal"
        >
          <Modal.Body>
            <div className="login-modal-body">
              <p>
                <strong>ERROR:</strong>
                We located your account in our legacy system but your password
                does not match.
              </p>
              <div className="confirm-email-btn-block">
                <button
                  type="submit"
                  className="v2-btn standard mobile-full-width"
                  onClick={() => closeBdiModal()}
                >
                  Forgot your password ?
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {emailVerification && (
        <EmailVerification
          emailVerification={emailVerification}
          emailId={emailId}
          history={history}
          studentId={loginStudentId}
          loginResponse={loginResponse}
        />
      )}
    </div>
  );
};
export default withRouter(LoginForm);
