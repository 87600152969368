import moment from "moment";
import { EncryptStorage } from "../../services/EncryptStorage";
import { getRefFieldValue } from "./flexi-field/utils";

const domainRestriction = (param) => {
  if (param && param.organisationCustomAttributeMappingList) {
    param.organisationCustomAttributeMappingList.map((attribute) => {
      if (attribute.customAttribute.columnName === "DOMAIN_RESTRICTION") {
        EncryptStorage.setToLocalStorage(
          "domainRestriction",
          attribute.customAttributeValue
        );
        return attribute.customAttributeValue;
      }
    });
  }
};

const emailVerificationEnabled = (param) => {
  let returnValue = false;

  param &&
    param.map((attribute) => {
      if (attribute.customAttribute.columnName === "EMAIL_VERIFICATION") {
        returnValue = attribute.customAttributeValue === "true" ? true : false;
      }
    });
  return returnValue;
};

const mobileNumberEnable = (param) => {
  let returnValue = false;
  if (param && param.organisationCustomAttributeMappingList) {
    param.organisationCustomAttributeMappingList.map((attribute) => {
      if (attribute.customAttribute.columnName === "REMOVE_FIELDS") {
        returnValue =
          attribute.customAttributeValue == "true" ||
          (attribute.customAttributeValue &&
            typeof attribute.customAttributeValue === "boolean")
            ? true
            : false;
      }
    });
  }
  return returnValue;
};

const getStudentRegisterPayloadForWithStateId = (values, isMailVerified) => {
  return {
    emailId: values.emailId,
    firstName: values.firstName,
    isStudentOptMail: values.isStudentOptMail,
    isStudentOptSms: values.isStudentOptSms,
    lastName: values.lastName,
    mobileNumber: values.mobileNumber,
    parentEmail: values.parentEmail,
    password: values.password,
    isVerified: isMailVerified,
    state: {
      stateId: Number(values.state),
    },
  };
};

const setStudentRegisterFormValue = (values, isMailVerified) => {
  return {
    emailId: values.emailId,
    firstName: values.firstName,
    isStudentOptMail: values.isStudentOptMail,
    isStudentOptSms: values.isStudentOptSms,
    lastName: values.lastName,
    mobileNumber: values.mobileNumber,
    parentEmail: values.parentEmail,
    isVerified: isMailVerified,
    password: values.password,
    utmCampaign: values.utmCampaign || "",
    utmContent: values.utmContent || "",
    utmMedium: values.utmMedium || "",
    utmSource: values.utmSource || "",
    utmTerm: values.utmTerm || "",
    aaid: values.a_aid || "",
    abid: values.a_bid || "",
    acid: values.a_cid || "",
    visitorId: values.visitorID || "",
    affiliateId: values.affiliateID || "",
    referral: values.referral || "",
    trafficType: values.trafficType || "",
    utmGadSource: values.gad_source || "",
    gclid: values.gclid || "",
  };
};

const setStudentRegisterPayloadValue = (
  isStateFollowActive,
  withStateId,
  formValue,
  values
) => {
  const payload = isStateFollowActive ? withStateId : formValue;
  if (isStateFollowActive) {
    EncryptStorage.setToLocalStorage("stateId", Number(values.state));
  }
  return {
    student: Object.assign(
      {
        organisation: {
          organisationId: EncryptStorage.getLocalStorage("organisationId"),
        },
        status: "PaymentPending",
        isActive: true,
      },
      payload
    ),
  };
};

const setNewStudentRegisterPayload = (
  tempFlexiFieldObject,
  item,
  configCourseId
) => {
  let temp_FlexiFieldObject = tempFlexiFieldObject;
  temp_FlexiFieldObject.push({
    flexiField: {
      flexiFieldId: item.flexiFieldId,
    },
    isActive: true,
    refFieldId: item.flexiFieldType === "DROPDOWN" ? refFieldId(item) : null,
    refFieldValue: getRefFieldValue(item),
    configCourseId: configCourseId,
  });
  return temp_FlexiFieldObject;
};

const refFieldId = (item) => {
  if (item.flexiFieldType === "DROPDOWN") {
    return item.value ? Number(item.value) : null;
  } else {
    return null;
  }
};

const checkCountyObejct = () => {
  return (
    localStorage.OtherCountyObject &&
    localStorage.OtherCountyObject != null &&
    localStorage.OtherCountyObject != "null" &&
    localStorage.OtherCountyObject != ""
  );
};

const setUserObjectAndStudentId = (res) => {
  let studentId = "";
  let userObject = "";
  let registerEmail = "";
  let registerFirstName = "";
  let registerLastName = "";
  if (res.data.student) {
    studentId = res.data.student.studentId;
    registerEmail = res.data.student.emailId;
    registerFirstName = res.data.student.firstName;
    registerLastName = res.data.student.lastName;
  }
  if (res.data.studentId) {
    studentId = res.data.studentId;
    registerEmail = res.data.emailId;
    registerFirstName = res.data.firstName;
    registerLastName = res.data.lastName;
  }
  if (res.data.student) {
    userObject = JSON.stringify(res.data.student);
  }
  if (res.data.studentId) {
    userObject = JSON.stringify(res.data);
  }
  EncryptStorage.setToLocalStorage("registerEmail", registerEmail);
  EncryptStorage.setToLocalStorage("registerFirstName", registerFirstName);
  EncryptStorage.setToLocalStorage("registerLastName", registerLastName);
  EncryptStorage.setToLocalStorage("userObject", userObject);
  EncryptStorage.setToLocalStorage("studentId", studentId);
  return studentId;
};

const setPaymentProcessPayload = () => {
  let organisationId = EncryptStorage.getLocalStorage("organisationId");
  let configCourseId = EncryptStorage.getLocalStorage("configCourseId");
  let studentId = EncryptStorage.getLocalStorage("studentId");
  let payload = {
    totalAmount: 0,
    paymentGateway: "BYPASSPAYMENT",
    isGatewayBypass: true,
    organisationId: organisationId,
    studentPaymentTrx: [
      {
        totalAmount: 0,
        paymentGateway: "BYPASSPAYMENT",
        student: {
          studentId: studentId,
        },
        configCourse: {
          configCourseId: configCourseId,
        },
      },
    ],
  };
  if (organisationId && organisationId.toString() === "1481") {
    payload["isGSACourse"] = true;
  }
  return payload;
};

const checkSubmitError = (submitError, values) => {
  if (!submitError) {
    localStorage.setItem("flexiFieldValues", JSON.stringify(values));
    localStorage.setItem("flexiFieldValidated", true);
    if (values.COUNTY) {
      EncryptStorage.setToLocalStorage("countyId", values.COUNTY);
    }
    if (values.COURT && values.COURT != "Other") {
      EncryptStorage.setToLocalStorage("courtId", values.COURT);
    }
    if (values.CITATIONDATE) {
      let _date = new Date(values.CITATIONDATE);
      let _yyyymmdd = moment(_date).format("YYYY-MM-DD");
      EncryptStorage.setToLocalStorage("citationDate", _yyyymmdd);
    }
  } else {
    localStorage.setItem("flexiFieldValidated", false);
  }
};

const checkTrue = (value) => {
  let res = false;
  if (value != undefined && value.toString() === "true") {
    res = true;
  }
  return res;
};

const checkFalse = (value) => {
  let res = false;
  if (value != undefined && value.toString() === "false") {
    res = true;
  }
  return res;
};

const trackAffliate = () => {
  // let cookie = {};
  // let blockingFilter = false;
  // document.cookie.split(";").forEach(function (el) {
  //   let [k, v] = el.split("=");
  //   //if (k.trim() === "PAPVisitorId"){ // && v.trim() === "641963accb631"){
  //     console.log(k + " - " + v + " - " + el);  
  //     //console.log(v);
  //   //}
  //   cookie[k.trim()] = v;
  // });
  // let affId = cookie["PAPAffiliateId"];
  // EncryptStorage.setToLocalStorage("affliateId", affId);
  // if (window.PostAffTracker) {
  //   window.PostAffTracker.setAccountId("default1");
  //   window.PostAffTracker.track();
  // }
};

const getIDsFromURL = (agreeFlag, rulesFlag) => {
  const currentUrl = window.location.href;
  let utmObject = {};

  let utmSource;
  let utmMedium;
  let utmCampaign;
  let utmTerm;
  let utmContent;
  let base_url;
  let a_aid;
  let a_bid;
  let a_cid;
  let visitorID;
  let affiliateID;
  let referral;
  let gad_source;
  let gclid;
  let PAPAffiliateId;
  let trafficType;

  if (String(currentUrl).includes("#")) {
    const hashPart = currentUrl.split("#")[1];
    const hashParams = new URLSearchParams(hashPart);
    utmSource = hashParams.get("utm_source");
    utmMedium = hashParams.get("utm_medium");
    utmCampaign = hashParams.get("utm_campaign");
    utmTerm = hashParams.get("utm_term");
    utmContent = hashParams.get("utm_content");
    base_url = hashParams.get("base_url");
    a_aid = hashParams.get("a_aid");
    a_bid = hashParams.get("a_bid");
    a_cid = hashParams.get("a_cid");
    visitorID = hashParams.get("visitorID");
    affiliateID = hashParams.get("affiliateID");
    referral = hashParams.get("referral");
    gad_source = hashParams.get("gad_source");
    gclid = hashParams.get("gclid");
    PAPAffiliateId = hashParams.get("PAPAffiliateId");
    trafficType = hashParams.get("trafficType");
  } else {
    const parsedUrl = new URL(String(currentUrl));

    utmSource = parsedUrl.searchParams.get("utm_source");
    utmMedium = parsedUrl.searchParams.get("utm_medium");
    utmCampaign = parsedUrl.searchParams.get("utm_campaign");
    utmTerm = parsedUrl.searchParams.get("utm_term");
    utmContent = parsedUrl.searchParams.get("utm_content");
    base_url = parsedUrl.searchParams.get("base_url");

    a_aid = parsedUrl.searchParams.get("a_aid");
    a_bid = parsedUrl.searchParams.get("a_bid");
    a_cid = parsedUrl.searchParams.get("a_cid");
    visitorID = parsedUrl.searchParams.get("visitorID");
    affiliateID = parsedUrl.searchParams.get("affiliateID");
    referral = parsedUrl.searchParams.get("referral");
    gad_source = parsedUrl.searchParams.get("gad_source");
    gclid = parsedUrl.searchParams.get("gclid");
    PAPAffiliateId = parsedUrl.searchParams.get("PAPAffiliateId");
    trafficType = parsedUrl.searchParams.get("trafficType");
  }

  utmSource = cleaningFields(utmSource, false);
  utmMedium = cleaningFields(utmMedium, false);
  utmCampaign = cleaningFields(utmCampaign, false);
  utmTerm = cleaningFields(utmTerm, false);
  utmContent = cleaningFields(utmContent, false);
  base_url = cleaningFields(base_url, true);
  a_aid = cleaningFields(a_aid, false);
  a_bid = cleaningFields(a_bid, false);
  a_cid = cleaningFields(a_cid, false);
  visitorID = cleaningFields(visitorID, false);
  affiliateID = cleaningFields(affiliateID, false);
  referral = cleaningFields(referral, true);
  gad_source = cleaningFields(gad_source, false);
  gclid = cleaningFields(gclid, false);
  PAPAffiliateId = cleaningFields(PAPAffiliateId, false);

  if (trafficType != null) {
    if (isIDMatch(utmMedium, ["cpc"])) {
      trafficType = trafficType || "PPC";
    }  else if (a_aid || a_bid || a_cid) {
      trafficType = "AFFILIATE - AFFILIATEPRO";
    } else if (isIDMatch(referral, ["geico"]) || isIDMatch(utmSource, ["geico"]) || base_url.includes("geico")) {
      trafficType = trafficType || "GEICO";
      referral = trafficType;
    } else if (isIDMatch(referral, ["JERRYELECTRIC"])) { //validation needs revision
      trafficType = "REFERRAL – MAJOR INSURANCE";
    } else if (isIDMatch(referral, [".gov", "facebook"]) || isIDMatch(utmSource, [".gov", "facebook"]) || isIDMatch(base_url, [".gov", "facebook"])) {
      trafficType = trafficType || "REFERRAL - OTHER";
      referral = referral || base_url;
    } else if (isIDMatch(referral, ["referralcandy"]) || isIDMatch(utmSource, ["referralcandy"]) || isIDMatch(base_url, ["referralcandy"])) {
      trafficType = trafficType || "AFFILIATE - OTHER";
      referral = referral || "https://go.referralcandy.com/";
    } else if (utmSource !== "" || utmMedium !== "" && base_url !== "" || utmCampaign !== "") {
      trafficType = trafficType || "AFFILIATE - OTHER";
      referral = referral || base_url;
    } else if (!isIDMatch(base_url, ["google", "bing", "duckduckgo", "yahoo", "hotbot", "android", "www.myimprov.com"])) {
      trafficType = trafficType || "REFERRAL";
      referral = base_url;
    } else if (isIDMatch(base_url, ["google", "bing", "duckduckgo", "yahoo", "hotbot", "android", "www.myimprov.com"])) {
      trafficType = trafficType || "ORGANIC";
      referral = base_url;
    }  else {
      trafficType = trafficType || "DIRECT";
    }
  }

  utmObject = {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    a_aid,
    a_bid,
    a_cid,
    visitorID,
    affiliateID,
    referral,
    trafficType,
    gad_source,
    gclid,
    PAPAffiliateId,
  };

  const tempObject = { ...utmObject };

  if (agreeFlag && rulesFlag) {
    localStorage.setItem("registerFormValues", JSON.stringify(tempObject));
    localStorage.setItem("registerFormValidated", true);
  } else {
    localStorage.setItem("registerFormValidated", false);
  }

  return utmObject;
}

const cleaningFields = (oldString, skip) => {
  if (skip){
    return oldString && oldString !== "" ? oldString : "";
  } else {
    let newstring = "";
    if (oldString || oldString !== "undefined") {
      newstring = oldString;
    }
    return newstring && newstring !== "" ? newstring.replace(/[^a-zA-Z0-9]/g, "") : "";
  }
}

const isIDMatch = (source, keywords) => {
  return keywords.some(keyword => source.includes(keyword));
};

export const registerHelper = {
  domainRestriction,
  emailVerificationEnabled,
  mobileNumberEnable,
  getStudentRegisterPayloadForWithStateId,
  setStudentRegisterFormValue,
  checkSubmitError,
  setStudentRegisterPayloadValue,
  checkTrue,
  checkFalse,
  setNewStudentRegisterPayload,
  checkCountyObejct,
  setUserObjectAndStudentId,
  setPaymentProcessPayload,
  trackAffliate,
  getIDsFromURL,
};
